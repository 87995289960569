$baseline: 1.7rem;

$basefontsize: 18px;
$basefontsize-medium: 16px;
$basefontsize-mobile: 15px;

$h1: 1.8rem;
$h2: 1.6rem;
$h3: 1.4rem;
$h4: 1.2rem;
$h5: 1rem;

$link: hsl(217, 71%,  53%);
$link-hover: darken($link, 20);

$title-line-height: 1.5em;
$footer-background-color: #FFF;

$fullhd-enabled: false !default
