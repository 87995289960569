aside {
    grid-area: aside;
}

main {
    grid-area: main;
    // @see: https://css-tricks.com/preventing-a-grid-blowout/
    min-width: 0;
}

header {
    grid-area: header;
}

footer {
    grid-area: footer;
}

.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "header"
        "main"
        "aside"
        "footer";
    max-width: 600px;
    padding: 0 1rem;
    margin: auto;

    @media screen and (min-width: 800px) {
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "header  header  header"
            "aside   main    main"
            "footer  footer  footer";
        max-width: 1124px;
        padding: 0 2rem;
        margin: auto;
    }

    @media screen and (min-width: 1050px) {
        display: grid;
        grid-gap: 4rem 6rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "header  header  header"
            "aside   main    main"
            "footer  footer  footer";
        max-width: 1124px;
        padding: 0 2rem;
        margin: auto;
    }
}


body.no-aside {
    .container {
        grid-template-areas:
            "header"
            "main"
            "footer";

        @media screen and (min-width: 800px) {
            grid-template-columns: 0fr 6fr 0fr;
            grid-template-areas:
                "header  header  header"
                ".       main    ."
                "footer  footer  footer";
        }
    }

    aside {
        display: none;
    }
}

.main > h1, .main > h2, .main > h3, .main > h4,
.sidebar > h1, .sidebar > h2, .sidebar > h3, .sidebar > h4 {
    margin-top: 0;
}
