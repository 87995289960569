.article-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 0rem;
}

.article-grid + .article-grid {
  margin-top: 1rem;
}

.article-grid figure, .article-grid img {
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.article-grid .col-1 { grid-column: span 1; }
.article-grid .col-2 { grid-column: span 2; }
.article-grid .col-3 { grid-column: span 3; }
.article-grid .col-4 { grid-column: span 4; }
.article-grid .col-5 { grid-column: span 5; }
.article-grid .col-6 { grid-column: span 6; }
.article-grid .col-7 { grid-column: span 7; }
.article-grid .col-8 { grid-column: span 8; }
.article-grid .col-9 { grid-column: span 9; }
.article-grid .col-10 { grid-column: span 10; }
.article-grid .col-11 { grid-column: span 11; }
.article-grid .col-12 { grid-column: span 12; }

.article-grid div p {
  margin-top: 0;
  margin-bottom: 0;
}

.article-grid div p + p {
  margin-top: 1rem;
}

@media only screen and (max-width: 600px) {
  .article-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .article-grid .col-1,
  .article-grid .col-2,
  .article-grid .col-3,
  .article-grid .col-4,
  .article-grid .col-5,
  .article-grid .col-6,
  .article-grid .col-7,
  .article-grid .col-8,
  .article-grid .col-9,
  .article-grid .col-10,
  .article-grid .col-11,
  .article-grid .col-12 {
    grid-column: span 1;
    margin-bottom: 1rem;
  }
}