html, body {
	font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: 'Open Sans', sans-serif;
	font-size: $basefontsize-mobile;
	line-height: $baseline;
	padding: $baseline * 0.5 0;

	@media screen and (min-width: 800px) {
		font-size: $basefontsize-medium;
	}
	@media screen and (min-width: 1050px) {
		font-size: $basefontsize;
	}
}



p {
	line-height: $baseline;
	margin-bottom: $baseline;
}

a:hover {
	text-decoration: underline !important;
}

h1, h2, h3, h4, h5, header > span {
	font-weight: 700;
    margin-bottom: $baseline * 0.5;
    font-family: 'Merriweather', serif;
}

h1, header > span {
	font-size: $h1;
	line-height: $h1 * 1.5;
	margin: 0;

	a {
		color: $body-color;
		text-decoration: none !important;
	}

	a:hover {
		color: $link;
		text-decoration: underline !important;
	}
}

h2, h1.title {
	font-size: $h2;
	line-height: $h2 * 1.5;
	margin-top: calc(2 * #{$baseline} - #{$h2});

	a {
		color: $body-color;
		text-decoration: none !important;
	}

	a:hover {
		color: $link;
		text-decoration: underline !important;
	}
}

h3 {
	font-size: $h3;
	line-height: $h3 * 1.5;
	margin-top: calc(2 * #{$baseline} - #{$h3});

}

h4 {
	font-size: $h4;
	line-height: $h4 * 1.5;
	margin-top: calc(2 * #{$baseline} - #{$h4});

}

h5 {
	font-size: $h5;
	line-height: $h5 * 1.5;
	margin-top: calc(2 * #{$baseline} - #{$h5});
}

ol {
	margin: 0 0 1rem 1.2rem;
}

ul {
	margin: 0 0 1rem 1.2rem;
	list-style-type: disc;
}

blockquote {
	border-left: 4px solid #CCC;
	background: #F8F8F8;
	padding: 0.5rem 1rem;
	margin-bottom: 1rem;
}


// Fix Prism styles.
code[class*="language-"], code, pre {
	font-size: 14px;
	line-height: 18px;
}

pre {
	.tag {
		margin: 0;
		padding: 0;
		background-color: transparent;
		display: inherit;
		font-size: inherit;
	}
	.number {
		font-size: 1em;
	}
}